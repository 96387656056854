exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-editor-js": () => import("./../../../src/pages/editor.js" /* webpackChunkName: "component---src-pages-editor-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-tools-aspect-ratio-calculator-js": () => import("./../../../src/pages/tools/aspect-ratio-calculator.js" /* webpackChunkName: "component---src-pages-tools-aspect-ratio-calculator-js" */),
  "component---src-pages-tools-case-converter-js": () => import("./../../../src/pages/tools/case-converter.js" /* webpackChunkName: "component---src-pages-tools-case-converter-js" */),
  "component---src-pages-tools-index-js": () => import("./../../../src/pages/tools/index.js" /* webpackChunkName: "component---src-pages-tools-index-js" */),
  "component---src-pages-tools-investment-calculator-js": () => import("./../../../src/pages/tools/investment-calculator.js" /* webpackChunkName: "component---src-pages-tools-investment-calculator-js" */),
  "component---src-pages-tools-price-per-kilogram-to-price-per-pound-converter-js": () => import("./../../../src/pages/tools/price-per-kilogram-to-price-per-pound-converter.js" /* webpackChunkName: "component---src-pages-tools-price-per-kilogram-to-price-per-pound-converter-js" */),
  "component---src-pages-tools-price-per-pound-to-price-per-kilogram-converter-js": () => import("./../../../src/pages/tools/price-per-pound-to-price-per-kilogram-converter.js" /* webpackChunkName: "component---src-pages-tools-price-per-pound-to-price-per-kilogram-converter-js" */),
  "component---src-pages-tools-px-to-rem-converter-js": () => import("./../../../src/pages/tools/px-to-rem-converter.js" /* webpackChunkName: "component---src-pages-tools-px-to-rem-converter-js" */),
  "component---src-pages-tools-pyeong-to-sqft-converter-js": () => import("./../../../src/pages/tools/pyeong-to-sqft-converter.js" /* webpackChunkName: "component---src-pages-tools-pyeong-to-sqft-converter-js" */),
  "component---src-pages-tools-pyung-to-sqft-converter-js": () => import("./../../../src/pages/tools/pyung-to-sqft-converter.js" /* webpackChunkName: "component---src-pages-tools-pyung-to-sqft-converter-js" */),
  "component---src-pages-tools-rem-to-px-converter-js": () => import("./../../../src/pages/tools/rem-to-px-converter.js" /* webpackChunkName: "component---src-pages-tools-rem-to-px-converter-js" */),
  "component---src-pages-tools-sqft-to-pyeong-converter-js": () => import("./../../../src/pages/tools/sqft-to-pyeong-converter.js" /* webpackChunkName: "component---src-pages-tools-sqft-to-pyeong-converter-js" */),
  "component---src-pages-tools-sqft-to-pyung-converter-js": () => import("./../../../src/pages/tools/sqft-to-pyung-converter.js" /* webpackChunkName: "component---src-pages-tools-sqft-to-pyung-converter-js" */),
  "component---src-pages-tools-tfsa-contribution-room-calculator-js": () => import("./../../../src/pages/tools/tfsa-contribution-room-calculator.js" /* webpackChunkName: "component---src-pages-tools-tfsa-contribution-room-calculator-js" */),
  "component---src-pages-tools-width-and-height-aspect-ratio-calculator-js": () => import("./../../../src/pages/tools/width-and-height-aspect-ratio-calculator.js" /* webpackChunkName: "component---src-pages-tools-width-and-height-aspect-ratio-calculator-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog/post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-categories-list-js": () => import("./../../../src/templates/categories-list.js" /* webpackChunkName: "component---src-templates-categories-list-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "component---src-templates-tags-list-js": () => import("./../../../src/templates/tags-list.js" /* webpackChunkName: "component---src-templates-tags-list-js" */)
}

